import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { robotListfleetId } from '../API'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Battery80Icon from '@mui/icons-material/Battery80';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useDispatch } from 'react-redux';
import { robotlistdataAction, robotmsgAction } from '../redux/actions';
import ringer from "./beep-07a.mp3";
function IndividualRobot(props) {

 const dispatch=useDispatch()
 const [robotlist, setrobotlist] =useState()   
 let robotmsg=[]
 const token = localStorage.getItem('token')
 const navigate =useNavigate()
 const audio = new Audio(ringer);
 audio.loop = true;

 useEffect(()=>{
  setInterval(()=>{
  // console.log('props?.data.fleetId',props?.data.fleetId,'token',token)
    robotListfleetId(props?.data.fleetId,token)
    .then((res)=>{
      //  console.log('RobotStatus by FleetID Response',res)
       let robotDisplaydata = res.data.data
       setrobotlist(robotDisplaydata) 
         //  console.log('robotDisplaydata',robotDisplaydata)
       return robotDisplaydata
    })
    .catch((err)=>{
        // console.log('RobotStatus by FleetID Error',err)
    })
  },8000)
},[])

 const [isCheck, setisCheck]=useState(true)
   
 const handlerobotdata=(robotdata)=>{
        // console.log('RobotDataRobotData',robotdata)
      //  dispatch(robotDataAction(robotdata[0]))
  //  console.log('RobotDataRobotData',robotdata)
   localStorage.setItem('fleetId',robotdata[0].fleetId)
   navigate(`/dashboard/${robotdata[0].robotId}`,{state:{name:robotdata[0],latitude:props.latitude, longitude:props.longitude}})
} 

 const playbeepsound=()=>{
  function play(){
    // audio.play() 
    // audio.loop = true
    console.log('Inside Inside')
  } 
   play() 
 }
const getTeleopStatus=(item,data)=>{
  switch(item.teleop){
    case "YES":
       playbeepsound()
        robotmsg.push({
        fleetId:data?.fleetId,
        fleetName:data?.fleetName,
        robotStatus:item.robotStatus,
        robotId:item.robotId,
        robotBattery:item.robotBattery,
        latitude:data.latitude,
        longitude:data.longitude
      })
      if(isCheck){
        setInterval(()=>{
            dispatch(robotmsgAction(robotmsg))
            setisCheck(false)
            },2000)
          } 
    default:
      audio.loop = false
      return null  
  }
}
const getrobotStatus=(item,data)=>{
    switch(item.robotStatus){
     case "AVAILABLE":
       return  <p className="text-left m-0 p-color-green">Available</p>
     case "UNAVAILABLE":
       return  <p className="text-left m-0 p-color-red"> UnAvailable</p>  
     case "CHARGING":
      return  <p className="text-left m-0 p-color-green">Charging</p> 
     case "ERROR":
       robotmsg.push({
         fleetId:data?.fleetId,
         fleetName:data?.fleetName,
         robotStatus:item.robotStatus,
         robotId:item.robotId,
         robotBattery:item.robotBattery,
         latitude:data.latitude,
         longitude:data.longitude
       })
       if(isCheck){
        setTimeout(()=>{
            dispatch(robotmsgAction(robotmsg))
            setisCheck(false)
            },2000)
       }
       return <p className="text-left m-0 p-color-red"> Error</p>
      default:
       return <p className="text-left m-0 p-color-red"> {item.robotStatus}</p>  
      }
}

  return (
    <>
      {robotlist?.length > 0 ? 
      <div className='SelectPage-robotList-wrapper'>
              {/* {console.log('robotlistrobotlist',robotlist)}  */}
            {robotlist?.map((item)=>{
               return (
                <div className='border-blue-top SelectFleetPage-inner-li-div d-flex-center' onClick={()=>handlerobotdata(item)}> 
                   <div>
                     <p className="SelectFleetPage-inner-li-div-robot-id" style={{color:'white'}}> {item[0].robotId}</p>
                     {getrobotStatus(item[0], props.data)}
                     {getTeleopStatus(item[0], props.data)}
                  </div>
               <div className="dashboard-left-side-div-one-inner-div"> 
                    <div  className="dashboard-left-side-div-one-wrapper-battery-div">
                  <Battery80Icon style={{color:'rgb(113, 207, 98)' , transform:'rotate(90deg)', fontSize:'24px'}}/>
                    <p style={{fontSize:'10px'}}>{parseInt(item[0].robotBattery).toFixed(0)}%</p> 
                  </div> 
                   <SignalCellularAltIcon style={{color:'white' , margin:'7px', marginTop:'0px'}}/>
                   {item[0].robotStatus==="AVAILABLE" ? <PauseCircleIcon className="play-icon p-color-green"/>:<PlayCircleIcon className="play-icon p-color-red"/>}
             </div> 
          </div> 
          
             )})}
       </div> 
       : <div className='SelectPage-robotList-wrapper'> 
         <h4 className='No-robots-available'>   No Robots Available</h4>        
         </div>}
       </>
  )
}

export default React.memo(IndividualRobot)