
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { latitudeaction, logitudeaction } from '../redux/actions';
import * as L from 'leaflet';
import Maptwo from './Maptwo';
import { iconPerson } from './IconMarker';
import { iconPickup } from './iconPickup';
import { iconDropoff } from './iconDropoff';

function MapMarker(props) {

 const updatemap=(lat,lng)=>{
    // var newLatLng = new L.LatLng(lat, lng);
    // marker?.setLatLng(newLatLng).addTo(map);
    if(lat ===NaN || lng===NaN){
      return null
    }
    else {
      var newlatlng=[lat,lng]
      console.log('newlatlng',newlatlng)
      for (var i=0;i<newlatlng.length;i++){
        if(newlatlng[i]===NaN){
         return null
        }
      }
      marker?.setLatLng(newlatlng).addTo(map).update();
      console.log('marker',marker)
    }
 }
 var map
 var marker
var pickupmarker
var dropoffmarker
  const ltd=useSelector(state=>state.latitudeReducer)
  const lng =useSelector(state=>state.longitudeReducer)
  const init=(lat, lon , locationData)=>{
    // console.log('lat',lat,'lon',lon)
    let pickupLocations=locationData.filter(item=>item.locationType==="PICKUP")
    let dropoffLocations=locationData.filter(item=>item.locationType==="DROPOFF")
    console.log('pickupLocations',pickupLocations,'dropoffLocations',dropoffLocations)
    map = L.map('map', {
      // Set latitude and longitude of the map center (required)
      center: [parseFloat(lat),parseFloat(lon)],
      // Set the initial zoom level, values 0-18, where 0 is most zoomed-out (required)
      zoom: 1
     });
      // Create a Tile Layer and add it to the map
      var tiles = new L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          minZoom: '2'}).addTo(map);

        marker = L.marker(
         [parseFloat(lat),parseFloat(lon)],
         {  draggable: false,
            title: "",
            opacity: 0.75
          });  
       marker.setIcon(iconPerson)  
    marker.addTo(map).bindPopup("I am an orange leaf."); 
    for (var i = 0; i < pickupLocations.length; i++) {
          pickupmarker = new L.marker([parseFloat(pickupLocations[i].lat),parseFloat(pickupLocations[i].lon)])
         .setIcon(iconPickup)
         .addTo(map).bindPopup("I am an orange leaf.");
      
    }
    for (var i = 0; i < dropoffLocations.length; i++) {
      dropoffmarker = new L.marker([parseFloat(dropoffLocations[i].lat),parseFloat(dropoffLocations[i].lon)])
     .setIcon(iconDropoff)
     .addTo(map).bindPopup("I am an orange leaf.");
  
}
}  
     const dispatch= useDispatch()

     const [latlongarr,setlatlonarr]=useState()
     var latitude=20
     var longitude=30
      useEffect(()=>{

        // console.log('latitude', latitude,"longitude", longitude)
        // dispatch(latitudeaction(props.latitude))
        // dispatch(logitudeaction(props.longitude)) 
       let lat =70
       let lng =-60
       setTimeout(()=>{
        init(props.initiallatitude,props.initiallongitude,props.locationData)
       }, 1000)
     
      
    },[latitude,longitude])
    return (
    <> 
    
     {(()=>{

            latitude  = parseFloat(props.latitude)
            longitude = parseFloat(props.longitude)

            if(latitude === NaN || longitude === NaN){
              return null
             }
             else{
             setInterval(()=>{   
                updatemap(latitude,longitude)
              },8000)
           }
            
 
  })()}
      <div id="map"></div>
      {/* {console.log('props.locationData', props.locationData)} */}
     {/* <Maptwo latitude={props.latitude} longitude={props.longitude}/> */}
    </>
  )
}

export default MapMarker
 