import React, { useEffect, useState } from 'react'
import {toast } from 'react-toastify'
import {  cancelOrderAPI, getrobotStatus, robotactionapi, UpdateRobotAction } from '../API'

function RobotActions(props) {
  
  const UpdateRobotError=()=> toast.error('Robot Update Command  Failed')
  const UpdateRobotSuccess=()=>toast.success('Robot Update Command Success')

  const CancelActionError=()=> toast.error('Cancel Action  Failed')
  const CancelActionSuccess=()=>toast.success('Cancel Action Command Success')
  const token = localStorage.getItem('token')
  const [robotData, setrobotData] =useState()
  const robotId = localStorage.getItem('robotId')
  const[commandlistdata, setcommandlistdata] =useState()
  const [orderId, setorderId] = useState()
  const [actionId, setactionId] =useState()
  useEffect(()=>{
    setInterval(()=>{
    getrobotStatus(robotId,token) 
    .then((res)=>{
      // console.log('Robot Action Response',res)
      setrobotData(res.data.data.robotStatus)
    })
    .catch((err)=>{
      // console.log('Robot Action Error',err)
    })
  },5000) 
   
    setInterval(()=>{
      robotactionapi(robotId,token)
      .then((res)=>{
        // console.log('CommandList Data', res)
        setcommandlistdata(res.data.robotCommandList)
        let orderiii= res.data.robotCommandList[0].orderId
        let actionId =  res.data.robotCommandList[0].actionId
        setorderId(orderiii)
        setactionId(actionId)
      })
      .catch((err)=>{
        // console.log('Robot Actions Error' ,err)
      })
    }, 3000)
   

  },[])

 const handlerobotAction=(status,actionId,orderId)=>{
   UpdateRobotAction(robotId,token, status,actionId, orderId)
   .then((res)=>{
   
     UpdateRobotSuccess()
    })
  .catch((err)=>{
    UpdateRobotError()
   })
 }  
 const handleCancelActionbutton=(robotData,actionId)=>{
  cancelOrderAPI(token, robotData.fleetId,actionId)
  .then((res)=>{
    CancelActionSuccess()
  })
  .catch((err)=>{
    CancelActionError()
  })
 } 
 const getrobotactionStatus=(commandlistdata,orderId,actionId)=>{
  console.log('commandlistdata?.actionStatus',commandlistdata?.actionStatus)
  switch(commandlistdata?.actionStatus){
    case "0":
      return (<div className='RobotActions-button-wrapper'>
             <button className="RobotActions-Start-btn" onClick={()=>handlerobotAction("1" , actionId,orderId)}>Start Action</button>
             <button className="RobotActions-cancel-btn" onClick={()=> handleCancelActionbutton(robotData,actionId)}> Cancel Action</button>
            </div>)
       
    case "1":
      return(<div className='RobotActions-button-wrapper'>
             <button className="RobotActions-Start-btn" onClick={()=>handlerobotAction("3",actionId,orderId)}>Finish Action</button>
             <button className="RobotActions-cancel-btn" onClick={()=> handleCancelActionbutton(robotData,actionId)}> Cancel Action</button>
            </div>)
     default:
      return null
  }
 } 
  return (
    <div className='RobotActions-content-wrapper-div'>
      {/* {console.log('commandlistdatacommandlistdata',commandlistdata)} */}
      {commandlistdata?.length >0 ?
      <><h3> {commandlistdata[0].actionType}</h3>
      <p> Order Id : <span>{commandlistdata[0].orderId}</span></p> 
      <p> Pickup Location : <span>{commandlistdata[0].locationName}</span></p> 
      <p> Compartement:<span>{commandlistdata[0].boxId}</span></p> 
      {getrobotactionStatus(commandlistdata[0],orderId,actionId)} 
      </>  : <p> No actions to show</p>}

    </div>
  )
}

export default RobotActions